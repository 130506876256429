nav{
    &.navbar{
        padding: 0px;
    }
    background-color: $headerbg;
    .nav-item{
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 5px;
        .icon{
            vertical-align: middle;
        }
        &.active{
            border-bottom: 2px solid $red1;
            background-color: $activenavbg;
        }
    }
}
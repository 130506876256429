#inro-text, #exclusive-features{
    padding: 65px 0;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    h3{
        padding-bottom: 10px;
        font-weight: 700;
        font-size: 24px;
    }
    p{
        color:#666;
        font-size: 18px;
    }
    figure{
        text-align: center;
    }
    .check-list{
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        li{
            vertical-align: top;
            padding-bottom: 10px;
            color: #333;
            font-size: 15px;
            line-height: 1.6em;
            background-position: 0 5px;
            padding-left: 37px;
            background: url(../img/check-compare.svg) left 8px no-repeat
        }
    }
}
.overview-intro{
    margin-top: 30px;
    position: relative;
    height: 400px;
    background-color: #000000;
    .with-video {
        padding-bottom: 400px;
        right: 0;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        opacity: .4;
        background: #000000;
        .embed-responsive-item{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border: 0;
            object-fit: cover;
        }
    }
    .container{
        height: 100%;
        position: relative;
        z-index: 2;
        .table-hold {
            display: table;
            width: 100%;
            height: 100%;
            .vertical-middle {
                display: table-cell;
                width: 100%;
                height: 100%;
                vertical-align: middle;
            }
            .header-section {
                text-align: center;
                color: #fff;
            }
        }
    }
}
.speed-test{
    padding-top: 60px;
    text-align: center;
    h2{
        font-size: 36px;
        color:#666;
        span{
            padding-bottom: 25px;
            border-bottom: 1px solid #ddd;
        }
    }
}
#headerCarousel{
    margin-top: 90px;
    .carousel-item{
        background-color: #666;
        img{
            width:100%;
            height: auto;
        }
    }
}
#headerCover{
    height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
footer{
    height: 600px;
    width: 100%;
    margin-top: 100px;
    position: relative;
    #gmap_canvas{
        width: 100%;
        height: 600px;
    }
    .footer-menu{
        position: absolute;
        bottom: 0px;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        color:#fff;
        padding: 20px 0;
        h4{
            margin-bottom: 15px;
            text-transform: uppercase;
        }
        ul{
            list-style: none;
            padding: 0px;
            margin:0px;
            a{
                color:#fff;
            }
        }
    }
}
.copyright {
    text-align: center;
    background-color: #000;
    .row{
        padding: 10px 0;
        color: #aaa;
    }
    a{
        color: #aaa;
    }
}